<template>
  <keep-alive>
    <v-text-field
      v-model="inputValue"
      ref="text"
      @compositionstart="waitingOnIme = true"
      @compositionend="compositionEnded"
      :error-messages="errors"
      :hint="hint"
      persistent-hint
    />
  </keep-alive>
</template>

<script>
import { validate } from 'vee-validate'
import { textInputMixin } from '@appsocially/vue-upil'

export default {
  mixins: [textInputMixin],
  data () {
    return {
      isValid: true,
      errors: []
    }
  },
  computed: {
    numericArg () {
      return this.node && this.node.args ? this.node.args.numeric : {}
    },
    style () {
      return this.numericArg.style || {}
    },
    hint () {
      return this.inputValue ? (new Intl.NumberFormat('ja-JP', this.style)).format(this.inputValue) : ''
    },
    // rules
    rules () {
      return this.numericArg.rules || {}
    },
    max () {
      return this.rules.max || false
    },
    min () {
      return this.rules.min || false
    },
    integer () {
      return this.rules.integer === true
    }
  },
  watch: {
    async inputValue (inputValue) {
      const { errors, valid } = await validate(inputValue, { numeric: true, max_value: this.max, min_value: this.min, integer: this.integer }, { name: 'このフィールド' })
      this.errors = errors
      this.isValid = valid
      this.onSubmit(inputValue)
    }
  }
}
</script>

<style>
</style>
